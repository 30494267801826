import React, { useContext } from "react";
import base from "../images/base.png";

const Welcome = () => {
  return (
    <div className="flex mx-auto max-w-6xl justify-center items-center">
      <div className="flex mf:flex-row flex-col items-start justify-between md:py-20 py-12">
        <div className="flex flex-1 justify-start items-start flex-col mf:mr-10">
          <h1 className="text-3xl sm:text-5xl text-white py-1">
            We here to celebrate diversity
          </h1>
          <p className="text-left text-light mt-5 text-white font-light md:w-9/12 w-11/12 text-base"></p>

          <button
            type="button"
            className="flex w-1/4 flex-row justify-center items-center my-5 p-3 rounded-full cursor-pointer bg-gradient-to-r from-blue-300 to-blue-500 hover:from-sky-400 hover:via-rose-400 hover:to-lime-400"
          >
            <p className="text-white text-base font-semibold">Join Discord</p>
          </button>
        </div>

        <div className="flex flex-col flex-1 items-center justify-start w-full mf:mt-10 ">
          <div className="flex justify-end items-start flex-col rounded-xl h-60 w-full eth-card .white-glassmorphism">
            <img src={base} alt="logo" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
