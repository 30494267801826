import { useState } from "react";
import { FaChevronUp } from "react-icons/fa";
interface ItemProps {
  title: string;
  children: any;
}

const Item = ({ title, children }: ItemProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border rounded shadow-sm text-white">
      <button
        type="button"
        aria-label="Open item"
        title="Open item"
        className="flex items-center justify-between w-full p-4 focus:outline-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        <p className="text-lg font-medium">{title}</p>
        <div className="flex items-center justify-center w-8 h-8">
          <FaChevronUp
            className={`w-3 text-white transition-transform duration-200 ${
              isOpen ? "transform rotate-180" : ""
            }`}
          />
        </div>
      </button>
      {isOpen && (
        <div className="p-4 pt-0">
          <p className="text-white">{children}</p>
        </div>
      )}
    </div>
  );
};

const FAQ = () => {
  return (
    <div className="flex flex-col mx-auto max-w-3xl justify-center items-center py-10">
      <div className="flex flex-col mb-16 sm:text-center">
        <h2 className="max-w-lg font-sans text-3xl font-bold leading-none tracking-tight text-white sm:text-4xl md:mx-auto">
          Frequently Asked Questions
        </h2>
      </div>
      <div className="space-y-4 w-full">
        <Item title="How Can I Buy a Rainbow Sheep?">
          After sales start, you can buy on mint page.
        </Item>
        <Item title="What is the mint price?">
          0.05 ETH for presale 0.07 ETH for public sale
        </Item>
        <Item title="How many can I mint?">Max 3 per person.</Item>
        <Item title="How many Rainbow Sheep are reserved?">
          We reserved 100 Rainbow Sheep marketing events, giveaways
        </Item>
        <Item title="How many Rainbow Sheep are reserved?">
          We reserved 100 Rainbow Sheep marketing events, giveaways
        </Item>
      </div>
    </div>
  );
};

export default FAQ;
