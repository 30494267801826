import React, { useContext } from "react";
import rainbow from "../images/rainbow.png";

const Welcome = () => {
  return (
    <div className="flex flex-col mx-auto max-w-3xl justify-center items-center py-10">
      <img src={rainbow} alt="rainbow" />
      <p className="text-3xl sm:text-4xl text-white py-4 text-center">
        Somewhere over the rainbow
        <br />
        The sheep are waiting to be minted
      </p>
    </div>
  );
};

export default Welcome;
