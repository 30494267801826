import React, { useContext } from "react";
import roadmap from "../images/roadmap.png";

const Roadmap = () => {
  return (
    <div className="flex flex-col mx-auto justify-center items-center py-10">
      <div className="pb-10">
        <h1 className="text-2xl sm:text-4xl text-white py-1">Road map</h1>
      </div>
      <div className="flex">
        <div className="basis-1/2">
          <img src={roadmap} alt="rainbow" />
        </div>
        <div className="basis-1/3">
          <div className="bg-gray-900 border border-gray-900 shadow-lg  rounded-3xl p-4 mb-5">
            <h1 className="text-2xl sm:text-3xl text-white py-1 font-semibold">
              Phase 1 - Art
            </h1>
            <p className="text-1xl sm:text-1xl text-white py-1">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries,
            </p>
          </div>
          <div className="bg-gray-900 border border-gray-900 shadow-lg  rounded-3xl p-4 mb-5">
            <h1 className="text-2xl sm:text-3xl text-white py-1 font-semibold">
              Phase 2 - Art
            </h1>
            <p className="text-1xl sm:text-1xl text-white py-1">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries,
            </p>
          </div>
          <div className="bg-gray-900 border border-gray-900 shadow-lg  rounded-3xl p-4 mb-5">
            <h1 className="text-2xl sm:text-3xl text-white py-1">
              Phase 3 - Art
            </h1>
            <p className="text-1xl sm:text-1xl text-white py-1">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries,
            </p>
          </div>
          <div className="bg-gray-900 border border-gray-900 shadow-lg  rounded-3xl p-4 mb-5">
            <h1 className="text-2xl sm:text-3xl text-white py-1">
              Phase 3 - Art
            </h1>
            <p className="text-1xl sm:text-1xl text-white py-1">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries,
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Roadmap;
