import React, { useContext } from "react";
import base from "../images/base.png";

const About = () => {
  return (
    <div className="flex mx-auto max-w-6xl justify-center items-center">
      <div className="flex mf:flex-row flex-col items-start justify-between md:py-20 py-12">
        <div className="flex flex-1 justify-start items-start flex-col mf:mr-10">
          <p className="text-1xl sm:text-1xl text-white py-1">
            ABOUT OUR PROJECT
          </p>
          <h1 className="text-2xl sm:text-4xl text-white py-1">
            What is Rainbow Sheep?
          </h1>
          <p className="text-left text-light mt-5 text-white font-light md:w-9/12 w-11/12 text-base">
            Rainbow Sheep is a collection of 5000 unique Sheep, categorized by
            levels of rarity and generated in 4K resolution with hundreds of
            high-quality, detailed elements. Stored as ERC-721 tokens, they will
            live on the Ethereum blockchain, as they make their way into the
            Rainbow Metaverse with their rightful owners.
          </p>

          <button
            type="button"
            className="flex w-1/4 flex-row justify-center items-center my-5 p-3 rounded-full cursor-pointer bg-gradient-to-r from-blue-300 to-blue-500 hover:from-sky-400 hover:via-rose-400 hover:to-lime-400"
          >
            <p className="text-white text-base font-semibold">Join Discord</p>
          </button>
        </div>

        <div className="flex flex-col flex-1 items-center justify-start w-full mf:mt-10 ">
          <div className="flex justify-end items-start flex-col rounded-xl h-60 w-full eth-card .white-glassmorphism">
            <img src={base} alt="logo" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
