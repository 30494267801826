import React from "react";
import {
  Navbar,
  Footer,
  Welcome,
  About,
  Rainbow,
  Roadmap,
  FAQ,
} from "./components";
import "./App.css";

function App() {
  return (
    <div className="min-h-screen">
      <div className="gradient-bg-welcome">
        <Navbar />
        <Welcome />
        <Rainbow />
        <About />
        <Roadmap />
        <FAQ />
      </div>
      <Footer />
    </div>
  );
}

export default App;
