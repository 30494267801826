import React from "react";
import { HiMenuAlt4 } from "react-icons/hi";
import { AiOutlineClose } from "react-icons/ai";

import logo from "../images/logo.png";

interface NavbarItem {
  title: string;
  classprops?: any;
}

const NavBarItem = ({ title, classprops }: NavbarItem) => (
  <li className={`mx-4 cursor-pointer ${classprops}`}>{title}</li>
);

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = React.useState(false);

  return (
    <nav className="mx-auto flex max-w-6xl justify-between py-4">
      <div className="md:flex-[0.5] flex-initial justify-center items-center text-white text-2xl sm:text-3xl py-1 font-semibold">
        {/* <img src={logo} alt="logo" className="w-32 cursor-pointer" /> */}
        Rainbow Sheep
      </div>
      <ul className="text-white md:flex hidden list-none flex-row justify-between items-center flex-initial">
        {["About", "Roadmap", "Team", "FAQ"].map((item, index) => (
          <NavBarItem key={item + index} title={item} />
        ))}
        <li>
          <button
            type="button"
            className="flex flex-row justify-center items-center p-3 rounded-full cursor-pointer bg-white hover:from-sky-400 hover:via-rose-400 hover:to-lime-400"
          >
            <p className="text-black text-base font-semibold">Join Discord</p>
          </button>
        </li>
      </ul>
      <div className="flex relative">
        {!toggleMenu && (
          <HiMenuAlt4
            fontSize={28}
            className="text-white md:hidden cursor-pointer"
            onClick={() => setToggleMenu(true)}
          />
        )}
        {toggleMenu && (
          <AiOutlineClose
            fontSize={28}
            className="text-white md:hidden cursor-pointer"
            onClick={() => setToggleMenu(false)}
          />
        )}
        {toggleMenu && (
          <ul
            className="z-10 fixed -top-0 -right-2 p-3 w-[70vw] h-screen shadow-2xl md:hidden list-none
            flex flex-col justify-start items-end rounded-md blue-glassmorphism text-white animate-slide-in"
          >
            <li className="text-xl w-full my-2">
              <AiOutlineClose onClick={() => setToggleMenu(false)} />
            </li>
            {["About", "Roadmap", "Team", "FAQ"].map((item, index) => (
              <NavBarItem
                key={item + index}
                title={item}
                classprops="my-2 text-lg"
              />
            ))}
          </ul>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
